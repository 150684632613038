import React from "react"
import styles from "./contact-form.module.scss"

const ContactForm = () => {
  return (
    <div className={styles["form-container"]}>
      <form
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
      >
        <input type="hidden" name="bot-field" />
        <div className={styles["input-group"]}>
          <input
            className={styles["input"]}
            type="text"
            name="name"
            id="name"
            required
          />
          <label htmlFor="name" className={styles["label-text"]}>
            Name
          </label>
        </div>

        <div className={styles["input-group"]}>
          <input
            className={styles["input"]}
            type="text"
            name="email"
            id="email"
            required
          />
          <label htmlFor="email" className={styles["label-text"]}>
            Email
          </label>
        </div>

        <div className={styles["input-group"]}>
          <textarea
            type="text"
            rows="5"
            name="name"
            id="message"
            placeholder="Message"
            required
          />
        </div>

        <div className="button-container">
          <button className={styles["button"]} type="submit">
            Send
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
