import React from "react"
import Layout from "../components/layout"
import Headline from "../components/headline/headline.component"
import ContactForm from "../components/contact-form/contact-form.component"
import SEO from "../components/seo"
import contactInfo from "../../site/settings/contact_info.json"

const ContactPage = () => {
  const subText = `You could contact me via my <a href="${contactInfo.linkedin}" target="_blank" rel="noopener noreferrer">Linkedin</a> or fill in the contact form below`
  return (
    <Layout>
      <SEO title="My Contact" description="Here is the ways to contact me" />
      <Headline mainText="Contact me!" subText={subText} />
      <div className="container">
        <h2 style={{ textAlign: "center", marginBottom: "0px" }}>
          Let's get in touch
        </h2>
        <ContactForm />
      </div>
    </Layout>
  )
}

export default ContactPage
